.logo-cofidoc {
  width: 85px;
  height: 85px;
  background-image: url("./images/cofidoc-logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logo-navbar-1 {
  width: 25px;
  height: 25px;
  background-image: url("./images/accueil.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logo-navbar-2 {
  width: 25px;
  height: 25px;
  background-image: url("./images/gestion-cofidoc.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.background-logo-mobility {
  width: 50px;
  height: 50px;
  background-image: url("./images/cofidoc-mobility-app.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.white-smile {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("./images/whiteSmile.png");
}

.background-mountain-cofidoc {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 0;
  background-image: url("./images/cofidoc-mountains.svg");
  background-size: cover;
}

.downloadApple {
  background: url("./images/apple.svg") no-repeat center center;
  display: flex;
  background-size: cover;
  height: 60px;
  width: 200px;
  border-radius: 10px;
  overflow: hidden;
}

.downloadAndroid {
  background: url("./images/google.png") no-repeat center center;
  display: flex;
  background-size: cover;
  height: 60px;
  width: 200px;
  border-radius: 10px;
  overflow: hidden;
}

.background-mountain-mobile-cofidoc {
  width: 100vw;
  height: 79vh;
  position: absolute;
  z-index: 0;
  background-image: url("./images/cofidoc-mountains.svg");
  background-size: cover;
  background-position: center;
}

.background-nav-bar {
  width: 170px;
  height: 100vh;
  left: 0px;
  position: absolute;
  z-index: 1;
  background-color: #faf7f7;
}
.background-over {
  width: 100vw;
  height: 100vh;
  left: 0px;
  position: absolute;
  z-index: 1;
  background-color: #faf7f79b;
}
.background-over-mobile {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 2;
  background-color: #f3e7e7c3;
}

.background-cloud {
  width: 100vw;
  bottom: 0;
  background-repeat: no-repeat;
  position: absolute;
  background-size: cover;
  z-index: 2;
  background-image: url("./images/clouds.svg");
}

.image-smile {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("../src/images/cofidoc-smile.svg");
}

.background-logo {
  width: 200px;
  height: 50px;
  background-image: url("./images/cofidoc-black.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.background-logo-mobility {
  width: 50px;
  height: 50px;
  background-image: url("./images/cofidoc-mobility-app.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.background-logo-taxi {
  width: 50px;
  height: 50px;
  background-image: url("./images/cofidoc-taxi-app.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.image-cofidoc-philippe-soual {
  width: 100%;
  height: 100%;
  background-image: url("./images/cofidoc-philippe-soual.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.image-cofidoc-metier {
  width: 100%;
  height: 100%;
  background-image: url("./images/cofidoc-metier.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.image-screen-mobility {
  background-image: url("./images/screenMobi.png");
  width: auto;
  height: 50vh; /* hauteur 100% de la fenêtre */
  border: 5px solid black; /* bordure autour de l'élément */
  background-size: auto 100%; /* hauteur 100%, largeur ajustée */
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
}

.image-netexporer1 {
  background-image: url("./images/netexplorer1.png");
  background-size: contain;
  width: 350px;
  min-width: 350px;
  height: 250px;
  min-height: 250px;
  border-radius: 10px;
}

.image-netexporer2 {
  background-image: url("./images/netexplorer2.png");
  background-size: contain;
  width: 350px;
  min-width: 350px;
  height: 250px;
  min-height: 250px;
  border-radius: 10px;
}
